import { createRouter, createWebHistory } from "vue-router";
import { routesAdmin } from "./admin";
// import Cookies from "js-cookie";
// import { decodeValue } from "@/service/crypto";

const allowedPaths = ['infant', 'society', 'army', 'medical', 'tour', 'lifecare', 'nurse', 'beauty', 'chef', 'cafe', 'ems', 'lifeservice', 'archi', 'software', 'fire', 'game', 'contents', 'lifetech', 'art', 'music', 'taekwondo', 'leports', 'lifecontents', 'freemajor']; // 리다이렉트할 문자열 목록
const routes = [
  {
    path: '/:anyPath',
    redirect: to => {
      const path = to.params.anyPath;
      // allowedPaths에 해당 경로가 포함될 경우에만 리다이렉트
      if (allowedPaths.includes(path)) {
        return `/department/${path}`;
      }
      // 해당되지 않는 경우는 리다이렉트하지 않음 (다른 라우트로 넘길 수 있음)
      return `/${path}`;
    }
  },
  {
    path: "",
    meta: { title: "경민대학교 입시사이트" },
    name: "HomePage",
    component: () => import("@/layouts/LayoutHomePage/LayoutHomePage.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/pages/HomePage/HomeUserPage.vue"),
      },
    ],
  },
  {
    path: "/department/:departmentId/notice",
    name: "DepartmentNoticePage",
    component: () => import("@/pages/DepartmentPage/DepartmentNotice.vue"),
  },
  {
    path: "/department/:departmentId",
    name: "DepartmentDetailPage",
    component: () =>
      import("@/layouts/LayoutChildrenPage/LayoutChildrenPage.vue"),
    children: [
      {
        path: "",
        name: "ChildrenPage",
        component: () => import("@/pages/DepartmentPage/DepartmentPage.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/department",
    name: "DepartmentListPage",
    component: () => import("@/pages/DepartmentPage/DepartmentListPage.vue"),
  },
  {
    path: "/category/:categoryId",
    name: "MenuPDFPage",
    component: () => import("@/layouts/LayoutMenuPage/LayoutMenuPage.vue"),
    children: [
      {
        path: "",
        name: "CategoryPage",
        component: () => import("@/pages/CategoryPage.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/notice/:type/:departmentId?",
    name: "NoticePage",
    component: () => import("@/layouts/LayoutMenuPage/LayoutMenuPage.vue"),
    children: [
      {
        path: "",
        name: "DetailNotice", // 각 라우트에 고유한 name을 주는 것을 권장
        component: () => import("@/pages/NoticePage/NoticePage.vue"),
        props: true,
      },
    ],
  },

  {
    path: "/faq-department/:faqId",
    name: "DepartmentFaqPage",
    component: () => import("@/pages/Faq/FaqDepartmentPage.vue"),
  },
  {
    path: "/notices",
    name: "NoticeDetailPage",
    component: () => import("@/pages/NoticePage/NoticeDetailPage.vue"),
  },
  {
    path: "/scholarship",
    name: "Scholarship",
    component: () => import("@/pages/ScholarshipPage/ScholarshipPage.vue"),
  },
  {
    path: "/faculty-info",
    name: "FacultyInfoPage",
    component: () => import("@/pages/FacultyInfoPage.vue"),
  },
  {
    path: "/search",
    name: "SearchPage",
    component: () => import("@/pages/SearchPage.vue"),
  },
  {
    path: "/pdf-viewer",
    name: "PDFViewer",
    component: () => import("@/components/PdfViewer.vue"),
  },
  {
    path: "/login",
    name: "LoginPage",
    component: () => import("@/pages/Auth/LoginPage.vue"),
  },
  {
    path: "/certified-university",
    name: "CertifiedUniversityPage",
    component: () => import("@/pages/BannerDetailPage/CertifiedUniversityPage.vue"),
  },
  {
    path: "/future-forward",
    name: "FutureForwardPage",
    component: () => import("@/pages/BannerDetailPage/FutureForwardPage.vue"),
  },
  {
    path: "/value-university",
    name: "ValueUniversityPage",
    component: () => import("@/pages/BannerDetailPage/ValueUniversityPage.vue"),
  },
  {
    path: "/admission-assistant",
    name: "AdmissionAssistantPage",
    component: () => import("@/pages/AdmissionAssistantPage.vue"),
  },
  {
    path: "/dormitory-application-guide",
    name: "DormitoryApplicationGuide",
    component: () => import("@/pages/DormitoryApplicationGuidePage.vue"),
  },
  {
    path: "/timestamp",
    name: "TimestampPage",
    component: () => import("@/pages/TimeStampPage.vue"),
  },
  {
    path: "/404",
    name: "404Page",
    component: () => import("@/pages/404/404Page.vue"),
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("@/pages/Error/ErrorPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes.concat(routesAdmin),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  const tokenCookies = document.cookie
    .split("; ")
    .find((row) => row.startsWith("_t"))
    ?.split("=")[1];
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!token) {
      next("/login");
    } else if (!tokenCookies) {
      next("/");
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
