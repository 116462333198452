import { createApp, h } from "vue";
import App from "./App.vue";
import Antd from "ant-design-vue";
import Vue3Toastify from "vue3-toastify";
import router from "./router/index.js";
import "./index.css";
import "ant-design-vue/dist/reset.css";
import "vue-lite-youtube-embed/style.css";
import "vue3-carousel/dist/carousel.css";
import "vue3-toastify/dist/index.css";
import "aos/dist/aos.css";
import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';
import "filepond/dist/filepond.min.css";
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';



const app = createApp({
  render() {
    return h(App);
  },
});

app.config.errorHandler = (err) => {
  console.error('에러가 발생했습니다:', err);
  // 에러 발생 시 에러페이지로 이동
  router.push({ name: 'Error' });
};
app.use(CkeditorPlugin)
app.use(Antd);
app.use(Vue3Toastify, { autoClose: 3000 });
app.use(router);
app.use(VCalendar, {})

app.mount("#app");
