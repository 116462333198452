<template>
  <div v-if="isVisible" class="fixed bottom-8 right-4 z-50 sm:bottom-16 sm:right-5">
    <a href="https://pf.kakao.com/_bTRcb" target="_blank" class="relative">
      <img
        src="/assets/images/button/kakao-link-pb1.png"
        alt="카카오 채널 바로가기"
        class="w-20 h-20 lg:w-20 lg:h-20 rounded-full shadow-lg transition-transform transform hover:scale-110 hover:opacity-0"
      />
      <img
        src="/assets/images/button/kakao-link-pb2.png"
        alt="카카오 채널 바로가기"
        class="w-20 h-20 lg:w-20 lg:h-20 rounded-full shadow-lg transition-transform transform scale-110 opacity-0 hover:opacity-100 absolute top-0 left-0"
      />
    </a>
    <!-- X 버튼 -->
    <button @click="hideButton" class="absolute top-0 right-0 text-gray rounded-full p-1 transform translate-x-1/2 -translate-y-1/2">
      X
    </button>
  </div>
</template>

<script>
export default {
  name: "KakaoChannelPb",
  data() {
    return {
      isVisible: true, // 버튼이 처음에는 보여짐
    };
  },
  methods: {
    hideButton() {
      this.isVisible = false; // X 버튼을 클릭하면 플로팅 버튼 숨김
    },
  },
};
</script>

<style></style>

<!-- <template>
  <div class="fixed bottom-8 right-4 z-50 sm:bottom-16 sm:right-5">
    <a href="https://pf.kakao.com/_bTRcb" target="_blank">
      <img
        src="/assets/images/button/kakao-link-pb1.png"
        alt="카카오 채널 바로가기"
        class="w-20 h-20 lg:w-20 lg:h-20 rounded-full shadow-lg transition-transform transform hover:scale-110 hover:opacity-0"
      />
      <img
        src="/assets/images/button/kakao-link-pb2.png"
        alt="카카오 채널 바로가기"
        class="w-20 h-20 lg:w-20 lg:h-20 rounded-full shadow-lg transition-transform transform scale-110 opacity-0 hover:opacity-100 absolute top-0 left-0"
      />
    </a>
  </div>
</template>

<script>
export default {
  name: "KakaoChannelPb",
};
</script>

<style></style> -->
