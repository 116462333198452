export const routesAdmin = [
  {
    path: "/admin",
    meta: { title: "경민대학교 입시사이트", requiresAuth: true },
    name: "AdminNoticePage",
    component: () =>
      import("@/layouts/admin/LayoutAdminPage/LayoutAdminPage.vue"),
    children: [
      {
        path: "",
        name: "AdminUserPage",
        component: () => import("@/pages/admin/UserPage.vue"),
      },
    ],
  },
  {
    path: "/admin/users",
    meta: { title: "경민대학교 입시사이트", requiresAuth: true },
    name: "UserPage",
    component: () =>
      import("@/layouts/admin/LayoutAdminPage/LayoutAdminPage.vue"),
    children: [
      {
        path: "",
        name: "UserPage",
        component: () => import("@/pages/admin/UserPage.vue"),
      },
    ],
  },
  {
    path: "/admin/departments",
    meta: { title: "경민대학교 입시사이트", requiresAuth: true },
    name: "AdminDepartmentPage",
    component: () =>
      import("@/layouts/admin/LayoutAdminPage/LayoutAdminPage.vue"),
    children: [
      {
        path: "",
        name: "AdminDepartmentPage",
        component: () => import("@/pages/admin/department/DepartmentPage.vue"),
      },
    ],
  },
  {
    path: "/admin/departments/:departmentId",
    meta: { title: "경민대학교 입시사이트", requiresAuth: true },
    name: "AdminDepartmentDetailPage",
    component: () =>
      import("@/layouts/admin/LayoutAdminPage/LayoutAdminPage.vue"),
    children: [
      {
        path: "",
        name: "AdminDepartmentDetailPage",
        component: () => import("@/pages/admin/department/DepartmentDetail.vue"),
      },
    ],




  },





  {
    path: "/admin/library",
    meta: { title: "경민대학교 입시사이트", requiresAuth: true },
    name: "LibraryPage",
    component: () =>
      import("@/layouts/admin/LayoutAdminPage/LayoutAdminPage.vue"),
    children: [
      {
        path: "",
        name: "LibraryContentPage",
        component: () => import("@/pages/admin/LibraryPage.vue"),
      },
    ],
  },
  {
    path: "/admin/news",
    meta: { title: "경민대학교 입시사이트", requiresAuth: true },
    name: "NewsPage",
    component: () =>
      import("@/layouts/admin/LayoutAdminPage/LayoutAdminPage.vue"),
    children: [
      {
        path: "",
        name: "NewsPage",
        component: () => import("@/pages/admin/NewsPage.vue"),
      },
    ],
  },
  {
    path: "/admin/faq",
    meta: { title: "경민대학교 입시사이트", requiresAuth: true },
    name: "FaqPage",
    component: () =>
      import("@/layouts/admin/LayoutAdminPage/LayoutAdminPage.vue"),
    children: [
      {
        path: "",
        name: "FaqPage",
        component: () => import("@/pages/admin/FaqPage.vue"),
      },
    ],
  },

  {
    path: "/admin/categories",
    meta: { title: "경민대학교 입시사이트", requiresAuth: true },
    name: "CategoriesPage",
    component: () =>
      import("@/layouts/admin/LayoutAdminPage/LayoutAdminPage.vue"),
    children: [
      {
        path: "",
        name: "CategoriesPage",
        component: () => import("@/pages/admin/CategoriesPage.vue"),
      },
    ],
  },
  {
    path: "/admin/video",
    meta: { title: "경민대학교 입시사이트", requiresAuth: true },
    name: "VideoPage",
    component: () =>
      import("@/layouts/admin/LayoutAdminPage/LayoutAdminPage.vue"),
    children: [
      {
        path: "",
        name: "VideoPage",
        component: () => import("@/pages/admin/VideoPage.vue"),
      },
    ],
  },
  {
    path: "/admin/schedule",
    meta: { title: "경민대학교 입시사이트", requiresAuth: true },
    name: "SchedulePage",
    component: () =>
      import("@/layouts/admin/LayoutAdminPage/LayoutAdminPage.vue"),
    children: [
      {
        path: "",
        name: "SchedulePage",
        component: () => import("@/pages/admin/SchedulePage.vue"),
      },
    ],
  },

  {
    path: "/admin/companies",
    meta: { title: "경민대학교 입시사이트", requiresAuth: true },
    name: "CompaniesPage",
    component: () =>
      import("@/layouts/admin/LayoutAdminPage/LayoutAdminPage.vue"),
    children: [
      {
        path: "",
        name: "CompaniesPage",
        component: () => import("@/pages/admin/CompaniesPage.vue"),
      },
    ],
  },
  {
    path: "/admin/parcel",
    meta: { title: "경민대학교 입시사이트", requiresAuth: true },
    name: "ParcelPage",
    component: () =>
      import("@/layouts/admin/LayoutAdminPage/LayoutAdminPage.vue"),
    children: [
      {
        path: "",
        name: "ParcelPage",
        component: () => import("@/pages/admin/ParcelPage.vue"),
      },
    ],
  },
];
