<template>
  <div>
    <router-view />
    <KakaoChannelPb></KakaoChannelPb>
  </div>
</template>

<script>
import KakaoChannelPb from "@/components/button/KakaoChannelPb.vue";
export default {
  name: 'App',
  components: {
    KakaoChannelPb
  }
}
</script>

<style >
.main-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 0
}
#app {
  background-color: rgba(235, 235, 235, 0.8)!important;
}
</style>
